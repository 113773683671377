import React from 'react'
import { motion } from "framer-motion"

const list = {
  visible: {
      opacity: 1,

      transition: {
          when: "beforeChildren",
          staggerChildren: 0.3,
          
      },
  },
  hidden: { opacity: 0 },

}

const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
const itemWidth = { visible: { height:'100%', transition: { duration: 1.5,delay:0.3 } }, hidden: { height:'0%'}, }
const Hakkimizda = () => {
  return (
    <motion.div initial="hidden" whileInView="visible" variants={list} className='pt-[5.2rem] '>

      <div className='grid grid-cols-6 '>
        <div className=' px-5 md:px-0 col-span-6 md:col-span-4 md:col-start-2    py-20 relative'>
          <motion.div variants={itemWidth} className='absolute h-full w-px bg-redloryColor  top-24 left-[1.66rem] md:left-1.5'></motion.div>

          <motion.div variants={itemText} className='w-full mb-16'>
            <div className='flex items-center space-x-3'>
              <div className='h-3.5 w-3.5 bg-redloryColor'></div>
              <h5 className='font-bold text-35 text-redloryColor'>
                Hakkımızda
              </h5>


            </div>

            <div className='pl-7 mt-5'>

            <p className='text-customGray text-18 mb-5'>
              Redlory Kompozit, endüstriyel kompozit çözümlerinde uzmanlaşmış bir şirkettir. Denizcilik, otomotiv, makine, medikal ve savunma sanayi gibi çeşitli sektörlerde faaliyet gösteren firmaların ihtiyaçlarına yönelik özelleştirilmiş çözümler sunmaktayız.
            </p>

            <p className='text-customGray text-18 mb-5'>
              Müşterilerimize yüksek kaliteli ürünler ve profesyonel hizmetler sunma vizyonuyla kurulan Redlory Kompozit, sektördeki uzun yıllara dayanan deneyimiyle dikkat çekmektedir. Her bir müşterinin benzersiz gereksinimlerine uygun çözümler üretme yeteneğimizle, müşteri memnuniyetini en üst düzeyde tutmayı amaçlıyoruz.
            </p>

            <p className='text-customGray text-18 mb-5'>
              Firmamız, sürekli olarak gelişen teknoloji ve endüstri trendlerini yakından takip ederek, müşterilerimize yenilikçi ve ileri teknolojiye dayalı çözümler sunmaktadır. Ürünlerimizin kalitesini artırmak ve sürekli iyileştirmek için Ar-Ge çalışmalarına büyük önem vermekteyiz.
            </p>

            </div>

          </motion.div>


          <motion.div variants={itemText} className='w-full  mb-24'>
            <div className='flex items-center space-x-3'>
              <div className='h-3.5 w-3.5 bg-redloryColor'></div>
              <h5 className='font-bold text-35 text-redloryColor'>
                Vizyon
              </h5>


            </div>

            <div className='pl-7 mt-5'>

            <p className='text-customGray text-18 mb-5'>
            • Faaliyet Alanlarında ürün ve hizmet kalitesi ile Türkiye’nin en değerli markası olmak,
            <br/>
            • Başta Denizli ve Ege Bölgesi olmak üzere tüm Türkiye sathında eğitime, sağlığa, çevreye ve kültürel değerlerimize sahip çıkarak daha müreffeh bir toplum oluşmasına katkıda bulunmak.
            </p>



            </div>

          </motion.div>



          
          <motion.div variants={itemText} className='w-full '>
            <div className='flex items-center space-x-3'>
              <div className='h-3.5 w-3.5 bg-redloryColor'></div>
              <h5 className='font-bold text-35 text-redloryColor'>
              Misyon
              </h5>


            </div>

            <div className='pl-7 mt-5'>

            <p className='text-customGray text-18 mb-5'>
            Müşterilerimize özel ihtiyaçlarına uygun çözümler sunarak, iş ortaklarımıza değer katmak ve sürdürülebilir başarıyı sağlamaktır. Yenilikçi ve kaliteli ürünlerimizle sektörde fark yaratırken, profesyonel yaklaşımımızla güvenilir bir iş ortağı olmayı sürdürüyoruz. Potansiyel müşterilere, ortaklara ve yatırımcılara ulaşarak, sektördeki liderliğimizi pekiştirmeyi ve büyümeyi hedefliyoruz.
            </p>



            </div>

          </motion.div>
        </div>

      </div>
    </motion.div>
  )
}

export default Hakkimizda