import React from 'react'
import { motion } from "framer-motion"
const HomeBanner = () => {


    const list = {
        visible: {
            opacity: 1,

            transition: {
                when: "beforeChildren",
                staggerChildren: 0.1,

            },
        },
        hidden: { opacity: 0 },

    }


    const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  return (
    <motion.div initial="hidden" whileInView="visible" variants={list} className='w-full h-full relative '>
    <img src='/images/close-up-perforated-fabric.jpg ' className='h-[28rem] lg:h-96 object-cover w-full'/>
    <div className='grid grid-cols-6 absolute top-0 left-0 w-full h-full  items-center'>

      <div className=' px-5 md:px-0 col-span-6 md:col-span-2 md:col-start-2     '>


        <motion.h5 variants={itemText} className='font-bold text-[2.7rem] text-white mb-5'>Profesyonel
          <br/>
           Kompozit Kalıp
           <br/>
           Çözümleri


        </motion.h5>

        <motion.p variants={itemText} className='text-white text-[0.9rem]'>
        Kusursuz sonuçlar için hassas kalıplar.
        </motion.p>




      </div>
    </div>
  </motion.div>
  )
}

export default HomeBanner