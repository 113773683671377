import React from 'react'
import { motion } from "framer-motion"
import { Link } from 'react-router-dom'
const HomeServices = () => {

    const list = {
        visible: {
            opacity: 1,

            transition: {
                when: "beforeChildren",
                staggerChildren: 0.1,

            },
        },
        hidden: { opacity: 0 },

    }


    const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  return (

    <motion.div initial="hidden" whileInView="visible" variants={list} className='grid grid-cols-6 '>
    <div className=' px-5 md:px-0 col-span-6 md:col-span-3 md:col-start-2    py-20'>
      <motion.h5 variants={itemText} className='font-bold text-[2.75rem] text-redloryColor py-10'>Hizmetlerimiz
      </motion.h5>

      <div className='w-full space-y-10'>

      <Link to={'/hizmetlerimiz/redlory-kompozit-kalip-tasarimi-ve-imalati'} className='inline-block'>
        <div className='w-full  hover:scale-105 transition-all duration-500 '>
          <div className='w-full flex items-start  space-x-10'>
            <motion.img variants={itemText} className='w-20' src='/images/hizmetlerimiz/1.svg' />
            <div className=''>
              <motion.h6 variants={itemText} className='text-[1.75rem] font-semibold'>REDLORY KOMPOZİT KALIP TASARIMI VE İMALATI
              </motion.h6>
              <motion.p variants={itemText} className='text-customGray text-[0.9rem]'>RedLory, yüksek kalite standartlarında kompozit kalıp tasarımı ve imalatı hizmetleri sunmaktadır. Uzman ekibimiz ve ileri teknoloji ekipmanlarımızla</motion.p>
             
              <motion.div variants={itemText} className='flex mt-3'>
                <p className='text-[0.7rem] text-redloryColor '>DAHA FAZLASI İÇİN</p>
                <img src='/images/hook.svg' />
              </motion.div>
             
            </div>
          </div>
        </div>
        </Link>



        <Link to={'/hizmetlerimiz/redlory-kompozit-kalip-prototipleme'} className='inline-block'>
        <div className='w-full   hover:scale-105 transition-all duration-500 '>
          <div className='w-full flex  items-start space-x-10'>
            <motion.img variants={itemText}  className='w-20' src='/images/hizmetlerimiz/2.svg' />
            <div className=''>
              <motion.h6 variants={itemText} className='text-[1.75rem] font-semibold'>REDLORY KOMPOZİT KALIP PROTOTİPLEME</motion.h6>
              <motion.p variants={itemText} className='text-customGray text-[0.9rem]'>RedLory olarak, kompozit kalıp tasarımı ve imalatında prototipleme sürecini mükemmeliyete taşıyoruz. Uzman ekibimiz, en son teknolojiyi kullanarak </motion.p>

              <motion.div variants={itemText} className='flex mt-3'>
                <p className='text-[0.7rem] text-redloryColor '>DAHA FAZLASI İÇİN</p>
                <img src='/images/hook.svg' />
              </motion.div>
     
            </div>
          </div>
        </div>
        </Link>





        <Link to={'/hizmetlerimiz/redlory-kompozit-dusuk-adetli-uretim'} className='inline-block'>
        <div className='w-full   hover:scale-105 transition-all duration-500 '>
          <div className='w-full flex  items-start space-x-10'>
            <motion.img variants={itemText}  className='w-20' src='/images/hizmetlerimiz/3.svg' />
            <div className=''>
              <motion.h6 variants={itemText} className='text-[1.75rem] font-semibold'>REDLORY KOMPOZİT DÜŞÜK ADETLİ ÜRETİM</motion.h6>
              <motion.p variants={itemText} className='text-customGray text-[0.9rem]'>RedLory Kompozit olarak, düşük adetli üretim konusunda uzmanız ve müşterilerimize özel çözümler sunuyoruz. Yüksek kaliteli kompozit kalıplarımız ve </motion.p>

              <motion.div variants={itemText} className='flex mt-3'>
                <p className='text-[0.7rem] text-redloryColor '>DAHA FAZLASI İÇİN</p>
                <img src='/images/hook.svg' />
              </motion.div>
     
            </div>
          </div>
        </div>
        </Link>





        






        





      </div>



    </div>
  </motion.div>
  )
}

export default HomeServices