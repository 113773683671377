import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"

const HomeAbout = () => {

    const list = {
        visible: {
            opacity: 1,

            transition: {
                when: "beforeChildren",
                staggerChildren: 0.1,

            },
        },
        hidden: { opacity: 0 },

    }


    const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  return (
    <motion.div initial="hidden" whileInView="visible" variants={list} className='grid grid-cols-6 '>
    <div className='px-5 md:px-0 col-span-6 md:col-span-3 md:col-start-2    py-20'>
      <motion.img variants={itemText} className='w-20' src='/images/about-icon.svg' />
      <motion.h5 variants={itemText} className='font-bold text-[2.75rem] text-redloryColor mb-5'>Hakkımızda

        </motion.h5>

      




              <motion.p variants={itemText} className='text-customGray text-[0.9rem] mb-5'>Redlory Kompozit, endüstriyel kompozit çözümlerinde uzmanlaşmış bir şirkettir. Denizcilik, otomotiv, makine, medikal ve savunma sanayi gibi çeşitli sektörlerde faaliyet gösteren firmaların ihtiyaçlarına yönelik özelleştirilmiş çözümler sunmaktayız.
              </motion.p>
              <motion.p variants={itemText} className='text-customGray text-[0.9rem] mb-8'>Müşterilerimize yüksek kaliteli ürünler ve profesyonel hizmetler sunma vizyonuyla kurulan Redlory Kompozit, sektördeki uzun yıllara dayanan deneyimiyle dikkat çekmektedir. Her bir müşterinin benzersiz
              </motion.p>


                <motion.div variants={itemText} >
              <Link to={'/hakkimizda'} className='hover:scale-105 transition-all duration-500  inline-block  border text-[0.7rem] text-customGray border-customGray py-3 px-14'>
                  DAHA FAZLASI
              </Link>
              </motion.div>




    </div>
  </motion.div>

  )
}

export default HomeAbout