import React from 'react'
import { motion } from "framer-motion"
import { Link } from 'react-router-dom'






const HomeProducts = () => {
    const list = {
        visible: {
            opacity: 1,

            transition: {
                when: "beforeChildren",
                staggerChildren: 0.3,

            },
        },
        hidden: { opacity: 0 },

    }


    const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
    const itemWidth = { visible: { height: '100%', transition: { duration: 1.5, delay: 0.3 } }, hidden: { height: '0%' }, }
    return (
        <div >
            <motion.div initial="hidden" whileInView="visible" variants={list}>
                <div className='grid grid-cols-6 '>
                    <div className=' px-5 md:px-0 col-span-6 md:col-span-4 md:col-start-2    py-20'>
                        <motion.h5  variants={itemText} className='font-bold text-[2.75rem] text-redloryColor mb-5'>Deneyimle Gelen Kompozit Üretimi
                            <br />

                            {/* Redlory Kompozit’te Bir Arada */}
                        </motion.h5>

                        <motion.p  variants={itemText} className='text-customGray text-[0.9rem]'>
                        İleri teknolojiye dayalı üretim süreçlerimiz ve uzman ekibimizle, projelerinizde güvenilir bir ortak olarak yanınızdayız.
                        </motion.p>




                    </div>
                </div>
            </motion.div>


            <motion.div initial="hidden" whileInView="visible" variants={list} className='grid grid-cols-6 '>
                <div className=' px-5 md:px-0 col-span-6 md:col-span-4 md:col-start-2 md:col-end-6    '>

                    <div className='grid grid-cols-1 md:grid-cols-2  gap-5'>
                        <Link to={'/uretim/kompozit-yat-imalati'}>
                        <div className=' group  relative overflow-hidden cursor-pointer hover:scale-105 transition-transform ease-in-out duration-1000'>
                            <motion.img variants={itemText} src='/images/home-main/9.jpg' className='group-hover:scale-125 transition-transform ease-in-out duration-1000 w-full' />
                            <img variants={itemText} src='/images/home-main/front.png '   className='absolute top-0 left-0 mix-blend-hard-light group-hover:opacity-0 transition-opacity ease-in-out duration-1000'/>
                            <div className='absolute h-full w-full top-0 left-0  z-10 p-8 flex items-end text-white text-35 font-bold group-hover:scale-105 transition-transform ease-in-out duration-500 origin-center '>
                                <div className='inline-block'>
                                    <h5>KOMPOZİT YAT İMALATI</h5>
                                    <div className='h-px w-0 bg-white group-hover:w-full  transition-all ease-in-out duration-1000'></div>
 
                                </div>
                                </div>
                        </div>

                        </Link>


                        <Link to={'/uretim/kompozit-tekne-imalati'}>
                        <div className=' group  relative overflow-hidden cursor-pointer hover:scale-105 transition-transform ease-in-out duration-1000'>
                            <motion.img variants={itemText} src='/images/home-main/10.jpg' className='group-hover:scale-125 transition-transform ease-in-out duration-1000 w-full' />
                            <img variants={itemText} src='/images/home-main/front.png '   className='absolute top-0 left-0 mix-blend-hard-light group-hover:opacity-0 transition-opacity ease-in-out duration-1000'/>
                            <div className='absolute h-full w-full top-0 left-0  z-10 p-8 flex items-end text-white text-35 font-bold group-hover:scale-105 transition-transform ease-in-out duration-500 origin-center '>
                                <div className='inline-block'>
                                    <h5>KOMPOZİT TEKNE İMALATI</h5>
                                    <div className='h-px w-0 bg-white group-hover:w-full  transition-all ease-in-out duration-1000'></div>
 
                                </div>
                                </div>
                        </div>
                        </Link>




                        <Link to={'/uretim/savunma-ve-havacilik'}>
                        <div className=' group  relative overflow-hidden cursor-pointer hover:scale-105 transition-transform ease-in-out duration-1000'>
                            <motion.img variants={itemText} src='/images/home-main/11.jpg' className='group-hover:scale-125 transition-transform ease-in-out duration-1000 w-full' />
                            <img variants={itemText} src='/images/home-main/front.png '   className='absolute top-0 left-0 mix-blend-hard-light group-hover:opacity-0 transition-opacity ease-in-out duration-1000'/>
                            <div className='absolute h-full w-full top-0 left-0  z-10 p-8 flex items-end text-white text-35 font-bold group-hover:scale-105 transition-transform ease-in-out duration-500 origin-center '>
                                <div className='inline-block'>
                                    <h5>SAVUNMA VE HAVACILIK</h5>
                                    <div className='h-px w-0 bg-white group-hover:w-full  transition-all ease-in-out duration-1000'></div>
 
                                </div>
                                </div>
                        </div>
                        </Link>





                        <Link to={'/uretim/kompozit-otomobil-parca-uretimi'}>
                        <div className=' group  relative overflow-hidden cursor-pointer hover:scale-105 transition-transform ease-in-out duration-1000'>
                            <motion.img variants={itemText} src='/images/home-main/12.jpg' className='group-hover:scale-125 transition-transform ease-in-out duration-1000 w-full' />
                            <img variants={itemText} src='/images/home-main/front.png '   className='absolute top-0 left-0 mix-blend-hard-light group-hover:opacity-0 transition-opacity ease-in-out duration-1000'/>
                            <div className='absolute h-full w-full top-0 left-0  z-10 p-8 flex items-end text-white text-35 font-bold group-hover:scale-105 transition-transform ease-in-out duration-500 origin-center '>
                                <div className='inline-block'>
                                    <h5>KOMPOZİT OTOMOBİL PARÇA ÜRETİMİ</h5>
                                    <div className='h-px w-0 bg-white group-hover:w-full  transition-all ease-in-out duration-1000'></div>
 
                                </div>
                                </div>
                        </div>
                        </Link>



                        <Link to={'/uretim/ozel-proje-kompozit'} className='md:col-span-2 '>
                        <div className=' h-48 md:h-auto  group  relative overflow-hidden cursor-pointer hover:scale-105 transition-transform ease-in-out duration-1000'>
                            <motion.img variants={itemText} src='/images/home-main/8.jpg' className='group-hover:scale-125 transition-transform ease-in-out duration-1000 w-full h-full object-cover' />
                            <img variants={itemText} src='/images/home-main/front2.png '   className='absolute top-0 left-0 mix-blend-hard-light group-hover:opacity-0 transition-opacity ease-in-out duration-1000 h-full w-full object-cover'/>
                            <div className='absolute h-full w-full top-0 left-0  z-10 p-8 flex items-end text-white text-35 font-bold group-hover:scale-105 transition-transform ease-in-out duration-500 origin-center '>
                                <div className='inline-block'>
                                    <h5>ÖZEL PROJE KOMPOZİT </h5>
                                    <div className='h-px w-0 bg-white group-hover:w-full  transition-all ease-in-out duration-1000'></div>
 
                                </div>
                                </div>
                        </div>
                        </Link>












                        {/* <div className=' md:col-span-2 relative'>
                            <motion.img variants={itemText} src='/images/home-main/4.jpg' />
                            <div className='absolute h-full w-full top-0 left-0  z-10 p-8 flex items-end text-white text-35 font-bold'>Özel Projeler </div>
                        </div> */}
                    </div>



                </div>
            </motion.div>

        </div>
    )
}

export default HomeProducts