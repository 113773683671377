import React, { useEffect, useRef } from 'react'
import Slider from '../components/Slider'
import { Link, useLocation } from 'react-router-dom'
import HomeButton from '../components/home/HomeButton'
import HomeProducts from '../components/home/HomeProducts'
import HomeServices from '../components/home/HomeServices'
import HomeBanner from '../components/home/HomeBanner'
import HomeAbout from '../components/home/HomeAbout'


import { motion } from "framer-motion"
const Home = () => {
  const z = useRef()
  const location = useLocation()

  useEffect(() => {

    if (location.hash === '#z') {
      window.scrollTo(0, z.current.offsetTop)
    }


  }, [location])
  return (
    <div >
      <Slider />

      <div className='h-[5rem] w-full  flex justify-center items-center'>
            <a href='/#z' className='h-[6vh] w-[6vh] relative flex justify-center items-center  hover:scale-105 transition-all duration-500'>
                <motion.img  src='/images/bottom-hook/2.svg' className='h-full w-full' />
                <img className='absolute transform rotate-90 h-full w-full' src='/images/bottom-hook/1.svg' />

            </a>
        </div>


        <div  ref={z} id='z' className='h-5 w-full opacity-0'></div>

<HomeProducts/>



    <HomeServices/>













<HomeBanner/>






<HomeAbout/>












    </div>
  )
}

export default Home