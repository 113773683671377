
import React from 'react'
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className='grid grid-cols-10 bg-redloryColor'>
      <div className='px-5 md:px-0 col-span-10 md:col-span-8 md:col-start-2  md:col-end-10    py-20'>

        <div className='flex flex-col'>
          <div className='w-full mb-10'>
            <img className='h-[2.1rem]' src='/images/logo-white.svg' />
          </div>
          <div className='flex w-full flex-col lg:flex-row'>
            <div className='grid grid-cols-1  lg:grid-cols-6 text-16 text-[rgba(255,255,255,0.6)]'>
              <div className='flex flex-col'>

                <a href='https://maps.app.goo.gl/XTwuyQjSgQQYBDGW9' target='_blank' className='mb-16 inline-block hover:scale-105 transition-all duration-500 hover:text-white'>
                  <p>
                    Karahasanli Mah. 3085. Sok. No:1
                    Merkezefendi DENİZLİ /TÜRKİYE
                  </p>
                </a>



                {/* <a href='tel:+905352035699' target='_blank' className='mb-1 inline-block hover:scale-105 transition-all duration-500 hover:text-white' >
                  <p >
                    +90 535 203 56 99
                  </p>
                </a> */}

                <a href='mailto:info@redlory.com.tr' className='hover:scale-105 transition-all duration-500 hover:text-white'>
                info@redlory.com.tr
                </a>

              </div>

              <div className='lg:col-start-2 lg:col-end-10 grid grid-cols-1 lg:grid-cols-4'>
                <div className='flex flex-col my-5 lg:my-0 lg:ml-10'>
                  <Link to={'/hakkimizda'} className='font-medium inline-block mb-5 hover:scale-105 transition-all duration-500 hover:text-white'>
                    KURUMSAL
                  </Link>
                  <Link to={'/hakkimizda'} className='hover:scale-105 transition-all duration-500 hover:text-white'>
                    Hakkımızda
                  </Link>
                  <Link to={'/hakkimizda'} className='hover:scale-105 transition-all duration-500 hover:text-white'>
                    Vizyon
                  </Link>
                  <Link to={'/hakkimizda'} className='hover:scale-105 transition-all duration-500 hover:text-white'>
                    Misyon
                  </Link>

                </div>



                <div className='flex flex-col my-5 lg:my-0 lg:ml-10'>
                  <Link to={'/uretim/kompozit-yat-imalati'} className='font-medium inline-block mb-5 hover:scale-105 transition-all duration-500 hover:text-white'>
                    ÜRETİM
                  </Link>
                  <Link to={'/uretim/kompozit-yat-imalati'} className='hover:scale-105 transition-all duration-500 hover:text-white'>
                  KOMPOZİT YAT İMALATI
                  </Link>
                  <Link to={'/uretim/kompozit-tekne-imalati'} className='hover:scale-105 transition-all duration-500 hover:text-white'>
                  KOMPOZİT TEKNE İMALATI
                  </Link>
                  <Link to={'/uretim/savunma-ve-havacilik'} className='hover:scale-105 transition-all duration-500 hover:text-white'>
                    Savunma ve Havacılık
                  </Link>
                  <Link to={'/uretim/kompozit-otomobil-parca-uretimi'} className='hover:scale-105 transition-all duration-500 hover:text-white'>
                  KOMPOZİT OTOMOBİL PARÇA ÜRETİMİ
                  </Link>
                  <Link to={'/uretim/ozel-proje-kompozit'} className='hover:scale-105 transition-all duration-500 hover:text-white'>
                  ÖZEL PROJE KOMPOZİT
                  </Link>

                </div>


                <div className='flex flex-col my-5 lg:my-0 lg:ml-10'>
                  <Link to={'/hizmetlerimiz/redlory-kompozit-kalip-tasarimi-ve-imalati'} className='font-medium inline-block mb-5 hover:scale-105 transition-all duration-500 hover:text-white'>
                    HİZMETLERİMİZ
                  </Link>
                  <Link to={'/hizmetlerimiz/redlory-kompozit-kalip-tasarimi-ve-imalati'} className='hover:scale-105 transition-all duration-500 hover:text-white'>
                  REDLORY KOMPOZİT KALIP TASARIMI VE İMALATI
                  </Link>
                  <Link to={'/hizmetlerimiz/redlory-kompozit-kalip-prototipleme'} className='hover:scale-105 transition-all duration-500 hover:text-white'>
                  REDLORY KOMPOZİT KALIP PROTOTİPLEME
                  </Link>
                  <Link to={'/hizmetlerimiz/redlory-kompozit-dusuk-adetli-uretim'} className='hover:scale-105 transition-all duration-500 hover:text-white'>
                  REDLORY KOMPOZİT DÜŞÜK ADETLİ ÜRETİM
                  </Link>

                </div>





                <div className='flex flex-col my-5 lg:my-0 lg:ml-10'>
                  <Link to={'/iletisim'} className='font-medium inline-block mb-5 hover:scale-105 transition-all duration-500 hover:text-white'>
                    İLETİŞİM
                  </Link>
                  <a href='mailto:info@redlory.com.tr' target='_blank' className='hover:scale-105 transition-all duration-500 hover:text-white'>
                    Mail
                  </a>
                  <Link to={'/iletisim'}  className='hover:scale-105 transition-all duration-500 hover:text-white'>
                    Adres
                  </Link>
                  {/* <a href='tel:+905352035699' target='_blank' className='hover:scale-105 transition-all duration-500 hover:text-white'>
                    Telefon
                  </a> */}


                  <a href='https://maps.app.goo.gl/a9p9ympRJzSPEKkA6' target='_blank' className='hover:scale-105 transition-all duration-500 hover:text-white'>
                    Google maps
                  </a>

                </div>




              </div>








            </div>
            <div className='flex flex-col items-end '>
                <img onClick={() => window.scrollTo(0, 0)} src='/images/top-hook.svg' className='min-w-6 max-w-6 cursor-pointer hover:scale-105 transition-all duration-500 hover:text-white' />

              </div>
          </div>

        </div>

      </div>

    </div>
  )
}

export default Footer