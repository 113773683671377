import { AnimatePresence } from "framer-motion";
import { useLocation , Routes , Route } from "react-router-dom";
import PageContainer from "./container/PageContainer";
import Home from "./pages/Home";
import Uretim from "./pages/Uretim";
import Hizmetlerimiz from "./pages/Hizmetlerimiz";
import Iletisim from "./pages/Iletisim";
import Hakkimizda from "./pages/Hakkimizda";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useEffect } from "react";

function App() {
  const location = useLocation();



  useEffect(() => {

      window.scrollTo(0, 0);

  }, [location]);
  return (
    <div className="App 3xl:container mx-auto overflow-x-hidden">
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<PageContainer />}>
              <Route  path="/" element={<Home/>}/>
              <Route  path="hakkimizda" element={<Hakkimizda/>}/>
              <Route  path="uretim" element={<Uretim/>}>
                  <Route path=":id"/>
              </Route>

              <Route  path="hizmetlerimiz" element={<Hizmetlerimiz/>}>
                  <Route path=":id"/>
              </Route>

              <Route  path="iletisim" element={<Iletisim/>}/>
          </Route>
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
