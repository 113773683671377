import React, { useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { motion } from "framer-motion"
const Slider = () => {

  
  const options = {
    // your options here, for example:
    duration: 1000,
    smooth: true,
  };

  const [isAnimating, setIsAnimating] = useState(0);


  return (
    <div className='pt-[5.2rem]'>
      <Swiper
              onSlideChange={(e) => setIsAnimating(e.activeIndex)}
        autoplay={{
          delay: 50000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,

        }}

        modules={[Autoplay, Pagination]}
        className=" h-[calc(100vh-10.2rem)]"
      >
        <SwiperSlide className="relative bg-[#252541] overflow-hidden">
          <motion.img animate={{ scale: isAnimating === 0 ? [1.1, 1,1.1] : 1,  transition: { duration: 15, ease: 'easeIn',   repeat: Infinity,repeatType: 'loop', } }}   className='h-full w-full object-cover' src='images/swiper/4.jpg' />
          <div className='absolute h-full w-full top-0 left-0  z-10 grid grid-cols-10'>
          <div className='px-5 md:px-0 col-span-10 md:col-span-8 md:col-start-2  md:col-end-10    py-20 flex md:items-end md:justify-end flex-col text-white'>
            <motion.h4 animate={{ opacity: isAnimating === 0 ? [0, 1] : 0,translateX: isAnimating === 0 ? [10, 0] : 0, transition: { duration: 2, ease: 'easeIn' } }} initial={{ opacity: 0 ,translateX:0}}  className='text-63 font-bold  text-start md:text-end  openSans mb-10 w-full'>
            Sektördeki En İleri
            <br/>
            Kompozit Çözümler
            </motion.h4>
            {/* <motion.p animate={{ opacity: isAnimating === 0 ? [0, 1] : 0, transition: { duration: 2, ease: 'easeIn' ,delay:1} }} initial={{ opacity: 0 }}  className='text-16 text-start md:text-end  font-bold  openSans w-full'>
            Redlory Kompozit, Sektördeki En Ileri Çözümleri 
            <br/>
            Sunuyor. İnovasyon Ve Kaliteyi Bir Araya Getirerek 
            <br/>
            Müşterilerimize Üstün Performans Ve Dayanıklılık Vaat Ediyoruz.
            </motion.p> */}
          </div>

          </div>

        </SwiperSlide>

        <SwiperSlide className="relative bg-[#252541]  overflow-hidden">
          <motion.img animate={{ scale: isAnimating === 1 ? [1.1, 1,1.1] : 1,  transition: { duration: 15, ease: 'easeIn',   repeat: Infinity,repeatType: 'loop', } }}   className='h-full w-full object-cover' src='images/swiper/5.jpg' />
          {/* <div className='absolute h-full w-full top-0 left-0  z-10 grid grid-cols-10'>
          <div className='px-5 md:px-0 col-span-10 md:col-span-8 md:col-start-2  md:col-end-10    py-20 flex md:items-end md:justify-end flex-col text-white'>
            <motion.h4 animate={{ opacity: isAnimating === 1 ? [0, 1] : 0,translateX: isAnimating === 1 ? [10, 0] : 0, transition: { duration: 2, ease: 'easeIn' } }} initial={{ opacity: 0 ,translateX:0}}  className='text-63 font-bold  text-start md:text-end  openSans mb-10 w-full'>
            Sektördeki En İleri
            <br/>
            Kompozit Çözümler
            </motion.h4>
            <motion.p animate={{ opacity: isAnimating === 1 ? [0, 1] : 0, transition: { duration: 2, ease: 'easeIn' ,delay:1} }} initial={{ opacity: 0 }}  className='text-16 text-start md:text-end  font-bold  openSans w-full'>
            Redlory Kompozit, Sektördeki En Ileri Çözümleri 
            <br/>
            Sunuyor. İnovasyon Ve Kaliteyi Bir Araya Getirerek 
            <br/>
            Müşterilerimize Üstün Performans Ve Dayanıklılık Vaat Ediyoruz.
            </motion.p>
          </div>

          </div> */}

        </SwiperSlide>



        <SwiperSlide className="relative bg-[#252541]  overflow-hidden">
          <motion.img animate={{ scale: isAnimating === 2 ? [1.1, 1,1.1] : 1,  transition: { duration: 15, ease: 'easeIn',   repeat: Infinity,repeatType: 'loop', } }}   className='h-full w-full object-cover' src='images/swiper/6.jpg' />
          {/* <div className='absolute h-full w-full top-0 left-0  z-10 grid grid-cols-10'>
          <div className='px-5 md:px-0 col-span-10 md:col-span-8 md:col-start-2  md:col-end-10    py-20 flex md:items-end md:justify-end flex-col text-white'>
            <motion.h4 animate={{ opacity: isAnimating === 1 ? [0, 1] : 0,translateX: isAnimating === 1 ? [10, 0] : 0, transition: { duration: 2, ease: 'easeIn' } }} initial={{ opacity: 0 ,translateX:0}}  className='text-63 font-bold  text-start md:text-end  openSans mb-10 w-full'>
            Sektördeki En İleri
            <br/>
            Kompozit Çözümler
            </motion.h4>
            <motion.p animate={{ opacity: isAnimating === 1 ? [0, 1] : 0, transition: { duration: 2, ease: 'easeIn' ,delay:1} }} initial={{ opacity: 0 }}  className='text-16 text-start md:text-end  font-bold  openSans w-full'>
            Redlory Kompozit, Sektördeki En Ileri Çözümleri 
            <br/>
            Sunuyor. İnovasyon Ve Kaliteyi Bir Araya Getirerek 
            <br/>
            Müşterilerimize Üstün Performans Ve Dayanıklılık Vaat Ediyoruz.
            </motion.p>
          </div>

          </div> */}

        </SwiperSlide>


      </Swiper>



    </div>
  )
}

export default Slider