import React, { useState } from 'react'
import { motion } from "framer-motion"
import { hizmetlerData } from '../data/hizmetler-data.js'
import { Link, useLocation, useParams } from 'react-router-dom'

import Lightbox from 'yet-another-react-lightbox'
import "yet-another-react-lightbox/styles.css";
import { Zoom } from 'yet-another-react-lightbox/plugins';
const Hizmetlerimiz = () => {




  const location = useLocation()

  const splitedLocation = location.pathname.split('/')
  const path1 = splitedLocation[2]
  const path2 = splitedLocation[3]

  console.log(path1)





  const filteredData = hizmetlerData.filter((item) => item.url === path1)[0]


console.log(filteredData)



const list = {
  visible: {
      opacity: 1,

      transition: {
          when: "beforeChildren",
          staggerChildren: 0.1,

      },
  },
  hidden: { opacity: 0 },

}


const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }

const [isOpen, setIsOpen] = useState(false);
const [currentImageIndex, setCurrentImageIndex] = useState(null);
const handleImageClick = (index) => {
  setCurrentImageIndex(index);
  setIsOpen(true);
};
  return (
    <div className='pt-[5.2rem] '>

<div className='grid grid-cols-6 '>
  {
       ((filteredData && filteredData.length !== 0)) ?
        <motion.div initial="hidden" whileInView="visible" variants={list} className=' px-5 md:px-0 col-span-6 md:col-span-4 md:col-start-2    py-20'>
          <motion.h5 variants={itemText} className='font-bold text-35 text-redloryColor mb-5'>{filteredData.title}
          </motion.h5>


          <div className='grid grid-cols-1 md:grid-cols-1  gap-5 mt-5'>

          {
              filteredData.image.map((item,index)=>{
                  return(
                    <div onClick={() => handleImageClick(index)}  className='relative group overflow-hidden cursor-pointer flex items-center justify-center hover:scale-95 transition-all duration-1000 ease-in-out'>
                        <div className='h-full w-full bg-[#00387F] absolute top-0 left-0 mix-blend-hard-light z-30 opacity-0 group-hover:opacity-100 transition-all duration-500'></div>
                       <img className='absolute z-40 w-[12%]  opacity-0 group-hover:opacity-100 transition-all duration-500' src='/images/icons/magnifying-glass-white.svg'/>
                    <motion.img  key={index} variants={itemText} src={item} className='w-full transition duration-500 scale-105 group-hover:scale-100'/>
                    </div>
                  )
              })
            }
          </div>

          <motion.p variants={itemText} className='text-customGray text-18 mt-10'>
          {filteredData.content_text_1}
          </motion.p>




          {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={filteredData.image.map((item,index) => ({ src: item, alt: item }))}
          index={currentImageIndex}
          plugins={[Zoom]}
        />
      )}

    







        </motion.div>
        :<div></div>
        }
        
      </div>





    </div>
  )
}

export default Hizmetlerimiz