export const hizmetlerData = [
    {
        id:0,
        url:'redlory-kompozit-kalip-tasarimi-ve-imalati',
        icon:'/images/menu/5.svg',
        image_main:'/images/uretim/1.jpg',
        title:'REDLORY KOMPOZİT KALIP TASARIMI VE İMALATI',
        content_text_1:'RedLory, yüksek kalite standartlarında kompozit kalıp tasarımı ve imalatı hizmetleri sunmaktadır. Uzman ekibimiz ve ileri teknoloji ekipmanlarımızla, müşterilerimizin ihtiyaçlarına özel çözümler üretmekteyiz. Tasarım sürecinde detaylı ihtiyaç analizi yaparak, simülasyonlarla optimize edilmiş kalıplar geliştiriyoruz. İmalat aşamasında ise en kaliteli malzemelerle, hassas üretim teknikleri kullanarak yüksek performanslı kalıplar üretiyoruz. RedLory olarak, tecrübemiz ve müşteri odaklı yaklaşımımızla projelerinizi başarıyla tamamlamayı taahhüt ediyoruz.',
        image:[
            '/images/hizmetlerimiz/kalip-tasarimi-imalati/1.jpg',
  

          ],


    },


    {
        id:1,
        url:'redlory-kompozit-kalip-prototipleme',
        icon:'/images/menu/1.svg',
        image_main:'/images/uretim/1.jpg',
        title:'REDLORY KOMPOZİT KALIP PROTOTİPLEME',
        content_text_1:'RedLory olarak, kompozit kalıp tasarımı ve imalatında prototipleme sürecini mükemmeliyete taşıyoruz. Uzman ekibimiz, en son teknolojiyi kullanarak tasarımlarınızı hayata geçirir ve prototiplerinizi hızlı ve güvenilir bir şekilde üretir. Prototipleme, tasarımlarınızın performansını ve işlevselliğini test etmenize olanak tanır. Kalite ve hassasiyetten ödün vermeden, ihtiyaçlarınıza özel çözümler sunarak projelerinizi başarıyla tamamlamanıza yardımcı oluyoruz. RedLory ile prototipleme sürecinde güvenilir bir ortak bulun.',
        image:[
            '/images/hizmetlerimiz/kalip-prototipleme/1.jpg',
   

          ],

    },



    {
        id:2,
        url:'redlory-kompozit-dusuk-adetli-uretim',
        icon:'/images/menu/3.svg',
        image_main:'/images/uretim/1.jpg',
        title:'REDLORY KOMPOZİT DÜŞÜK ADETLİ ÜRETİM',
        content_text_1:'RedLory Kompozit olarak, düşük adetli üretim konusunda uzmanız ve müşterilerimize özel çözümler sunuyoruz. Yüksek kaliteli kompozit kalıplarımız ve ileri teknoloji üretim ekipmanlarımızla, küçük partilerde bile mükemmel sonuçlar elde etmenizi sağlıyoruz. Her bir üretim aşamasında kalite ve hassasiyeti ön planda tutarak, müşteri memnuniyetini en üst düzeyde tutuyoruz. RedLory Kompozit ile düşük adetli üretimde güvenilir ve kaliteli çözümler için bize ulaşın.',
        image:[
            '/images/hizmetlerimiz/dusuk-adetli-uretim/1.jpg',


          ],


    },








]