import React from 'react'
import { motion } from "framer-motion"

const Iletisim = () => {


  const list = {
    visible: {
        opacity: 1,
  
        transition: {
            when: "beforeChildren",
            staggerChildren: 0.1,
  
        },
    },
    hidden: { opacity: 0 },
  
  }
  
  
  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  
  return (
    <div className='pt-[5.2rem] '>
      <motion.div initial="hidden" whileInView="visible" variants={list} className='grid grid-cols-6 '>

      <div className=' px-5 md:px-0 col-span-6 md:col-span-4 md:col-start-2    py-20'>
      <motion.h5 variants={itemText} className='font-semibold text-35 text-black mb-5 text-center'>İLETİŞİM DETAYLARI
      </motion.h5>

      <div className='grid grid-cols-1 md:grid-cols-2 w-full  gap-5 py-20  space-y-20 md:space-y-0'>
            {/* <motion.div variants={itemText} className='w-full  flex  items-center flex-col'>
              <img className='w-24 mb-10' src='/images/iletisim/2.svg'/>
              <a href='tel:+905352035699' target='_blank' className='hover:scale-105 transition-all duration-500 '>
                        <p className='text-16 font-light'>
                        +90 535 203 56 99
                      </p>
                      </a>

                  
            </motion.div> */}
            <motion.div variants={itemText} className=' w-full flex  items-center  flex-col'>
            <img  className='w-24 mb-10'  src='/images/iletisim/1.svg'/>
            <a href='mailto:info@redlory.com.tr' target='_blank' className='hover:scale-105 transition-all duration-500'>
                        <p className='text-16 font-light mb-1'>
                        info@redlory.com.tr
                      </p>
                      </a>
                      <div className='flex items-end space-x-3'>
                        <a href='#' target='_blank'><img className='h-3 hover:scale-105 transition-all duration-500'  src='/images/iletisim/menu-1.svg' /></a>
                        <a href='#' target='_blank'> <img  className='h-3 hover:scale-105 transition-all duration-500' src='/images/iletisim/menu-2.svg'/></a>
                        <a href='#' target='_blank'><img  className='h-3 hover:scale-105 transition-all duration-500' src='/images/iletisim/menu-3.svg'/></a>
                      </div>
            </motion.div>
            <motion.div variants={itemText} className='w-full  flex  items-center  flex-col'>
            <img className='w-24 mb-10'  src='/images/iletisim/3.svg'/>
            <a href='https://maps.app.goo.gl/a9p9ympRJzSPEKkA6' target='_blank' className='hover:scale-105 transition-all duration-500'>
                        <p className='text-16'>
                        Karahasanli Mah. 3085. Sok. No:1 
                        <br/>
                        Merkezefendi DENİZLİ /TÜRKİYE
                      </p>
                      </a>
            </motion.div>

      </div>


     
      </div>
      </motion.div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.5530363940984!2d29.037658!3d37.823937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c76aa906aec5dd%3A0x1f68efb57bf227d3!2sKarahasanl%C4%B1%20Mh.%2C%203085.%20Sk%20No%3A1%2C%2020050%20Merkezefendi%2FDenizli!5e0!3m2!1sen!2str!4v1719321985902!5m2!1sen!2str" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}

export default Iletisim